import env from '@/environment';
import * as axaPackage from '@axa-fr/oidc-client/package.json';
import { OidcProvider } from '@axa-fr/react-oidc';
import { Transition } from '@headlessui/react';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HelmetProvider } from 'react-helmet-async';
import { resolveValue, Toaster, ToastIcon } from 'react-hot-toast';
import 'react-loading-skeleton/dist/skeleton.css';
import { SWRDevTools } from 'swr-devtools';
import AuthError from './components/AuthError';
import ConfirmModal from './components/ConfirmModal';
import PageLoader from './components/PageLoader';
import { Router } from './Router';

function App() {
  return (
    <OidcProvider
      configuration={{
        client_id: env.CLIENT_ID,
        redirect_uri: env.REDIRECT_URI,
        silent_redirect_uri: env.SILENT_REDIRECT_URI,
        scope: 'openid profile email ecapi offline_access',
        authority: env.AUTHORITY,
        demonstrating_proof_of_possession: false,
        service_worker_relative_url: `/OidcServiceWorker.js?v=${axaPackage.version}`,
        service_worker_only: false,
      }}
      loadingComponent={PageLoader}
      authenticatingComponent={PageLoader}
      callbackSuccessComponent={PageLoader}
      authenticatingErrorComponent={() => {
        window.location.href = '/';
        console.log('Authenticating error');

        return null;
      }}
      sessionLostComponent={AuthError}
    >
      <HelmetProvider>
        <DndProvider backend={HTML5Backend}>
          <SWRDevTools>
            <Router />
            <ConfirmModal />
            <Toaster>
              {(t) => (
                <Transition
                  appear
                  show={t.visible}
                  className="transform p-4 flex bg-white dark:bg-gray-600 dark:text-white rounded shadow-lg"
                  enter="transition-all duration-150"
                  enterFrom="opacity-0 scale-50"
                  enterTo="opacity-100 scale-100"
                  leave="transition-all duration-150"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-75"
                >
                  <>
                    <ToastIcon toast={t} />
                    <div>
                      {resolveValue(t.message[0], null) && (
                        <p className="px-2 font-medium text-sm">{resolveValue(t.message[0], null)}</p>
                      )}
                      {resolveValue(t.message[1], null) && (
                        <p className="px-2 text-xs">{resolveValue(t.message[1], null)}</p>
                      )}
                    </div>
                  </>
                </Transition>
              )}
            </Toaster>
          </SWRDevTools>
        </DndProvider>
      </HelmetProvider>
    </OidcProvider>
  );
}

export default App;
