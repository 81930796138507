import Spinner from '@/components/Spinner';
import { fabric } from 'fabric';
import React, { useEffect, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import '../plugins/QrCode';

export const Preview = ({
  template,
  onSelect,
  small = false,
}: {
  template: any;
  onSelect?: (template: any) => void;
  small?: boolean;
}) => {
  const [url, setUrl] = React.useState('');
  const myRef = useRef();
  const { inViewport } = useInViewport(myRef);

  useEffect(() => {
    if (inViewport) {
      const canvas = new fabric.StaticCanvas(null);
      const fab = template?.content?.fabric ?? { objects: [] };
      const { width, height } = template?.content?.settings ?? { width: 0, height: 0 };

      canvas.setZoom(0.2);
      canvas.setWidth(width * canvas.getZoom());
      canvas.setHeight(height * canvas.getZoom());

      canvas.loadFromJSON({ background: 'white', ...fab }, () => {
        setUrl(
          canvas.toDataURL({
            format: 'jpeg',
            quality: 0.4,
          }),
        );
      });
    }
  }, [inViewport, url, template?.content?.fabric]);

  if (small) {
    return url ? (
      <img src={url} />
    ) : (
      <div ref={myRef} className="flex items-center justify-center m-2 text-slate-400 h-12">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="bg-white shadow-lg p-3 rounded-lg" onClick={() => onSelect?.(template)}>
      <div className="border mb-3">
        {url ? (
          <img src={url} />
        ) : (
          <div ref={myRef} className="flex items-center justify-center m-2 text-slate-400 h-24">
            <Spinner />
          </div>
        )}
      </div>
      <div className="text-sm font-bold text-gray-700 truncate text-center">{template.description || 'Kein Titel'}</div>
      {/* Trim text */}
      <div className="text-xs text-gray-500 whitespace-nowrap">
        {template.name?.length > 20 ? template.name.substring(0, 20) + '...' : template.name}
      </div>
    </div>
  );
};
