import AssetTile from '@/routes/AssetManager/AssetTile';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import DMAssetList from 'ec.sdk/lib/resources/publicAPI/DMAssetList';
import React, { useState } from 'react';
import useSWR from 'swr';
import useSdk from '../hooks/useSdk';
import AssetPickerModal from '../routes/AssetManager/AssetPickerModal';
import { uploadAssets } from '../util/assets';
import fileVariant from '../util/fileVariant';
import { AssetDropzone } from './AssetDropzone';
import ConfirmDelete from './ConfirmDelete';
import Img from './Img';
import Ink from './Ink';
import LocalLoader from './LocalLoader';
import Spinner from './Spinner';

// TODO: storybook?

export default function AssetPickerMulti(props: {
  group: string;
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  children?: any; // React.ReactNode | () => any;
  view?: 'tiles' | 'list';
  stock?: boolean;
}) {
  const { value, group, onChange, children = null, placeholder = 'Bilder auswählen', view } = props;
  const { api } = useSdk();
  const [open, setOpen] = useState(false);
  const [currentlyUploading, setCurrentlyUploading] = useState<string[]>([]);
  const [showClose, setShowClose] = useState<any>(undefined);
  const { data: selectedAssets, isValidating } = useSWR<DMAssetList>(
    value?.length && group ? `assets/${group}/${value.join(',')}` : null,
    async () => {
      return api.dmAssetList(group, { assetID: { any: value } });
    },
    {
      // use: [laggySWR], // keep stale data on reload
      revalidateOnFocus: false,
    },
  );

  const { data: loadedImages } = useSWR('selected' + JSON.stringify(value?.length), async () => {
    const images = [];
    for (const img of value) {
      if (typeof img === 'object') {
        images.push(img);
        continue;
      }
      const i = await api.dmAsset(group, img);
      images.push(i);
    }
    return images;
  });

  // const selectedAssets = value?.length ? loadedAssets : { items: [] }; // needed only when laggySWR is used
  return (
    <>
      <AssetDropzone
        onDrop={async ({ files }) => {
          setCurrentlyUploading(files);
          try {
            const assets = await uploadAssets(api, group, files);
            onChange?.([...(value || []), ...assets.map((asset) => asset.assetID)]);
          } catch (e) {
            if (e.message === 'no files') {
              // user has dragged the selected files back into the dropzone
              return;
            }
          } finally {
            setCurrentlyUploading([]);
          }
        }}
      >
        {children && typeof children === 'function' ? (
          children({ selectedAssets, currentlyUploadedFiles: currentlyUploading, setOpen, value })
        ) : (
          <div className="grid grid-cols-2 gap-4">
            {currentlyUploading?.map((file: any, i) => (
              <div key={i} className="rounded-md p-0 aspect-1 overflow-hidden relative group">
                <div
                  className=" cursor-pointer h-full bg-white dark:bg-gray-600 border border-dashed border-gray-400 dark:border-gray-400 flex flex-col space-y-4 items-center py-4 rounded-md text-gray-500 dark:text-gray-300"
                  onClick={() => setOpen(true)}
                >
                  <div className="flex flex-col h-full justify-center items-center gap-2">
                    <Spinner className="w-4 h-4" />
                    <span className="text-sm">{file.name} wird hochgeladen</span>
                  </div>
                </div>
              </div>
            ))}
            {false &&
              selectedAssets?.items?.map((asset) => (
                <div
                  key={asset.assetID}
                  className="dark:bg-gray-500 bg-gray-200 rounded-md p-0 aspect-[4/3] overflow-hidden relative group"
                >
                  <Img src={fileVariant(asset, 400)} className="h-full" />
                  <div className="absolute top-2 right-2 space-y-2 group-hover:opacity-100 opacity-0">
                    <Ink.Error onClick={() => onChange(null)} className="cursor-pointer">
                      <TrashIcon className="w-6 h-6 dark:bg-gray-800 bg-gray-100 p-1 rounded-md" />
                    </Ink.Error>
                  </div>
                </div>
              ))}
            {!currentlyUploading.length && (
              <div className="rounded-md aspect-1 w-44 overflow-hidden relative">
                <div
                  className=" cursor-pointer h-full p-2 bg-white dark:bg-gray-600 border border-dashed border-gray-400 dark:border-gray-400 flex flex-col space-y-4 items-center py-4 rounded-md text-gray-500 dark:text-gray-300"
                  onClick={() => setOpen(true)}
                >
                  <div className="flex h-full justify-center items-center gap-2">
                    <PencilIcon className="w-4 h-4" />
                    <span className="text-xs">{placeholder}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </AssetDropzone>
      <div className="flex flex-wrap mt-3 gap-4">
        {loadedImages?.map((asset) => (
          <AssetTile
            group={group}
            asset={asset}
            key={asset.assetID}
            className="w-24"
            onX={(a) => {
              const newAssets = value.filter((v: any) => (v.assetID ?? v) !== a.assetID);
              onChange?.(newAssets);
            }}
          />
        ))}
      </div>
      {isValidating && <LocalLoader />}
      <ConfirmDelete
        open={showClose !== undefined}
        cancelLabel="Bleiben"
        deleteLabel="Trotzdem schließen"
        onClose={() => setShowClose(undefined)}
        onDelete={() => {
          showClose.clear();
          setOpen(false);
          setShowClose(undefined);
        }}
        title="Änderungen gehen verloren"
        description="Du hast eins oder mehrere Assets ausgewählt. Wenn du fortfährst gehen diese verloren."
      />
      <AssetPickerModal
        stock={props.stock}
        view={view}
        assetGroupID={group}
        open={open}
        onClose={(manager) => {
          if (!manager || manager.selection.size === 0) {
            return setOpen(false);
          }
          setShowClose({ clear: manager.selection.clear });
        }}
        onChange={(items) => onChange?.(items)}
        value={value}
      />
    </>
  );
}
