import React from 'react';
import useSdk from './useSdk';
import useSWR from 'swr';

export default function usePermissionQuery(
  /** like 'member:requests' */
  permission: string,
) {
  const { api, accounts } = useSdk();

  return useSWR(api ? `dm:${api.dataManagerID}:club:?:${permission}` : null, async () => {
    const me = await accounts.me();

    return me.queryPermissions(`dm:${api.dataManagerID}:club:?:${permission}`);
  });
}
