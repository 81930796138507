import Badge from '@/components/Badge';
import Button from '@/components/Button';
import ConfirmDelete from '@/components/ConfirmDelete';
import Form from '@/components/Form';
import Input from '@/components/Input';
import Modal from '@/components/Modal';
import { Pagination } from '@/components/Pagination';
import Progress from '@/components/Progress';
import Spinner from '@/components/Spinner';
import { ListingTable } from '@/components/Table';
import useConfirm from '@/hooks/useConfirm';
import { useDiscountCodes, useTaskService } from '@/hooks/useDiscounts';
import useEntry from '@/hooks/useEntry';
import useNotifications from '@/hooks/useNotifications';
import useSdk from '@/hooks/useSdk';
import dayjs from '@/util/dayjs';
import { CloudArrowDownIcon, PlusIcon, PrinterIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { jsPDF } from 'jspdf';
import { nanoid } from 'nanoid';
import Papa from 'papaparse';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FilterCodesModal from './FilterCodesModal';
import { error } from 'xstate/lib/actions';

const PAGE_SIZE = 10;
export default function CodesTable({ showActions = true }) {
  const { voucherID } = useParams();
  const getCodes = useDiscountCodes();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE);
  const { data: codes, mutate } = getCodes(voucherID, {
    page,
    size: pageSize,
  });

  const not = useNotifications();
  const { data: discount } = useEntry({
    id: voucherID,
    model: 'discount_campaign',
    swrOptions: { revalidateOnFocus: false },
  });

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showPrintModal, setShowPrintModal] = React.useState(false);

  const { confirmUser, ConfirmProvider } = useConfirm();

  function printCodePDF(codesToPrint?: any) {
    const doc = new jsPDF({
      format: [85, 54],
      orientation: 'landscape',
    });
    doc.setFontSize(10);
    codesToPrint?.forEach((code, i) => {
      doc.text(code.code, 85 / 2, 54 / 2, { align: 'center' });
      if (i !== codesToPrint.length - 1) {
        doc.addPage();
      }
    });
    // doc.toDataURL((dataUrl) => {
    //   window.open(dataUrl, '_blank').focus();
    // });
    confirmUser('Sollen die Codes als gedruckt markiert werden?', 'Ja', 'Nein')
      .then(async () => {
        //TODO
        await Promise.all(codesToPrint.map(async (code) => setCodePrinted(code)));
        not.emit({ type: 'success', message: 'Codes als gedruckt markiert' });
        mutate();
      })
      .catch(() => not.emit({ type: 'success', message: 'Codes nicht als gedruckt markiert' }));

    doc.output('dataurlnewwindow');
  }

  function printCodes(codesToPrint?: any[]) {
    const iframe = document.createElement('iframe');

    iframe.src =
      window.location.pathname + (codesToPrint ? `/print?code=${codesToPrint.map((c) => c.id).join(',')}` : '/print');
    document.body.appendChild(iframe);

    const handleIframeMessage = (e: MessageEvent) => {
      if (e.data.type === 'startPrint') iframe.contentWindow?.print();

      if (e.data.type === 'afterPrint') {
        document.body.removeChild(iframe);
        iframe.contentWindow?.removeEventListener('message', handleIframeMessage);
        setShowPrintModal(false);

        confirmUser('Sollen die Codes als gedruckt markiert werden?', 'Ja', 'Nein')
          .then(async () => {
            //TODO
            await Promise.all(codesToPrint.map(async (code) => setCodePrinted(code)));
            not.emit({ type: 'success', message: 'Codes als gedruckt markiert' });
            mutate();
          })
          .catch(() => not.emit({ type: 'success', message: 'Codes nicht als gedruckt markiert' }));
      }
    };

    iframe.contentWindow?.addEventListener('message', handleIframeMessage);
  }

  const { api } = useSdk();
  async function setCodePrinted(code: { id: string }) {
    const entry = await api.entry('discount_code', code.id);
    entry.printed = dayjs().toISOString();
    entry.issued = dayjs().toISOString();
    await entry.save();
  }

  const [showCSVModal, setShowCSVModal] = React.useState(false);

  const [showCreateMore, setShowCreateMore] = React.useState(false);

  return (
    <div>
      <ConfirmProvider />
      {showActions && (
        <div className="flex gap-3 justify-end items-center mb-3">
          <span className="gap-3 inline-flex rounded-md ">
            {false && codes?.data.every((code) => !code.redeemed) && (
              <Button $danger onClick={() => setShowDeleteModal(true)}>
                <TrashIcon className="h-4 w-4 " aria-hidden="true" /> Aktion mit allen Codes löschen
              </Button>
            )}
            {codes?.meta.total > 0 && (
              <Button $secondary onClick={() => setShowCSVModal(true)}>
                <CloudArrowDownIcon className="h-4 w-4 text-gray-400 mr-2" aria-hidden="true" /> CSV herunterladen
              </Button>
            )}
            <CSVModal onClose={mutate} show={showCSVModal} setShow={setShowCSVModal} discountID={voucherID} />
            {codes?.meta.total > 0 && (
              <Button $secondary onClick={() => setShowPrintModal(true)}>
                <PrinterIcon className="h-4 w-4 text-gray-400 mr-2" aria-hidden="true" /> Codes drucken
              </Button>
            )}
            <FilterCodesModal
              headline="Welche Codes sollen gedruckt werden?"
              show={showPrintModal}
              discountID={voucherID}
              setShow={setShowPrintModal}
            >
              {(c) => <Button onClick={() => printCodePDF(c)}>Codes drucken</Button>}
            </FilterCodesModal>

            {discount?.activatedForSale ? (
              <Badge>Für Verkaufsgutscheine können keine Codes manuell erzeugt werden</Badge>
            ) : discount?.remainingCodes === -1 ? (
              <Badge>Code ist unbegrenzt einlösbar</Badge>
            ) : (
              <Button onClick={() => setShowCreateMore(true)}>
                <PlusIcon className="h-4 w-4 text-gray-400 mr-2 " aria-hidden="true" /> Codes hinzufügen
              </Button>
            )}
          </span>
        </div>
      )}
      <ListingTable
        header={['Code', 'Erstellt', 'Gedruckt', 'Exportiert', 'Eingelöst', 'Bezahlt', '']}
        data={codes?.data}
        cell={(code) => {
          return [
            <span className="font-bold">{code.code}</span>,
            dayjs(code.created).format('DD.MM.YY'),
            code.printed ? dayjs(code.printed).format('DD.MM.YY') : <XMarkIcon className="w-5 h-5 text-red-500" />,
            code.issued ? dayjs(code.issued).format('DD.MM.YY') : <XMarkIcon className="w-5 h-5 text-red-500" />,
            code.redeemed ? (
              `${dayjs(code.redeemed).format('DD.MM.YY')} ${code.email || ''}`
            ) : (
              <XMarkIcon className="w-5 h-5 text-red-500" />
            ),
            code.paid ? (
              `${dayjs(code.paid).format('DD.MM.YY')} ${code.email || ''}`
            ) : (
              <XMarkIcon className="w-5 h-5 text-red-500" />
            ),
            showActions && (
              <Button.Action tooltip="Code ausdrucken" onClick={() => printCodes([code])}>
                <PrinterIcon className="h-5 w-5 cursor-pointer text-gray-400" />
              </Button.Action>
            ),
          ];
        }}
      />
      <Pagination total={codes?.meta.total} count={PAGE_SIZE} hideTextInfo={false} value={page} onChange={setPage} />
      {showDeleteModal && <DeleteModal open={showDeleteModal} onClose={() => setShowDeleteModal(false)} />}
      <div className="flex gap-3 justify-end mt-3">
        <Modal open={showCreateMore} onClose={() => setShowCreateMore(false)}>
          <div className="flex gap-3 min-w-[50vw] justify-between">
            <h3>Weitere Codes anlegen</h3>
            <Button.Action onClick={() => setShowCreateMore(false)}>
              <XMarkIcon className="h-5 w-5 cursor-pointer text-gray-400" />
            </Button.Action>
          </div>
          <AddCodesModal
            forSale={discount?.activatedForSale}
            random={discount?.randomCodes}
            presetCode={discount?.code}
            onDone={() => {
              setShowCreateMore(false);
              mutate();
            }}
          />
        </Modal>
      </div>
    </div>
  );
}

function CSVModal({ show, setShow, onClose, discountID }) {
  const { data: discount } = useEntry({
    id: discountID,
    model: 'discount_campaign',
    swrOptions: { revalidateOnFocus: false },
  });

  //const csvData = useMemo(() => {
  //if (!codes || !discount) return [];
  //return codes.data.map((code) => ({
  //...code,
  //campaign_name: discount.name,
  //campaign_active: discount.active,
  //campaign_tags: discount.tags?.map((tag: any) => tag._entryTitle).join(', '),
  //campaign_dateStart: discount.dateStart,
  //campaign_dateEnd: discount.dateEnd,
  //campaign_limitedCodes: discount.limitedCodes,
  //campaign_hectorConfig: JSON.stringify(discount.hectorConfig),
  //}));
  //}, [codes, discount]);

  const { api } = useSdk();
  const notifications = useNotifications();
  const [isExporting, setIsExporting] = useState(false);
  async function exportData(codes: any[]) {
    setIsExporting(true);

    try {
      const csvData = codes.map(({ hectorConfig, ...code }) => ({
        id: code.id,
        code: code.code,
        email: code.email,
        created: dayjs(code.created).format('DD.MM.YY'),
        gedruckt: code.printed ? dayjs(code.printed).format('DD.MM.YY') : '',
        ausgegeben: code.issued ? dayjs(code.issued).format('DD.MM.YY') : '',
        eingelöst: code.redeemed ? dayjs(code.redeemed).format('DD.MM.YY') : '',
        bezahlt: code.paid ? JSON.stringify(code.paid) : 'Nein',
        campaign_name: discount.name,
        campaign_active: discount.active,
        campaign_tags: discount.tags?.map((tag: any) => tag._entryTitle).join(', '),
        campaign_dateStart: discount.dateStart,
        campaign_dateEnd: discount.dateEnd,
        campaign_limitedCodes: discount.limitedCodes,
        campaign_hectorConfig: JSON.stringify(discount.hectorConfig),
      }));

      const csv = Papa.unparse(csvData);
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'codes.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();

      await Promise.all(
        codes.map(async (code) => {
          const entry = await api.entry('discount_code', code.id);
          entry.issued = dayjs().toISOString();
          return entry.save();
        }),
      );

      setIsExporting(false);
      notifications.emit({
        type: 'success',
        message: 'Codes erfolgreich exportiert',
      });

      onClose();
      setShow(false);
    } catch (error) {
      setIsExporting(false);
      notifications.emit({
        type: 'error',
        message: 'Fehler beim Exportieren',
      });
    }
  }

  return (
    <FilterCodesModal
      headline="Welche Codes sollen gedruckt werden?"
      show={show}
      discountID={discountID}
      setShow={setShow}
    >
      {(c) => <Button onClick={() => exportData(c)}>CSV exportieren</Button>}
    </FilterCodesModal>
  );
}

function AddCodesModal({
  onDone,
  random,
  presetCode,
  forSale,
}: {
  onDone: () => void;
  random?: boolean;
  forSale: boolean;
}) {
  const [codes, setCodes] = React.useState([]);
  const { shortID, voucherID } = useParams();
  const [count, setCount] = React.useState(1);
  const [code, setCode] = React.useState('');

  const debounce = useRef(null);
  useEffect(() => {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      if (code !== '' && count > 0) {
        setCodes(
          Array(Number(count))
            .fill(0)
            .map(() => `${code}${random ? '-' + nanoid(5) : ''}`),
        );
      }
    }, 500);
  }, [code, count, random]);
  const not = useNotifications();

  const { taskStatus, setSettings } = useTaskService();
  async function addCodes() {
    setSettings((settings) => ({
      ...settings,
      url: 'hec.discount-create',
      params: {
        shortID,
        discountCampaignID: voucherID,
        count,
        code,
      },
      callback: () => {
        not.emit({ type: 'success', message: 'Discount created' });
        setTimeout(() => {
          onDone();
        }, 1000);
      },
      onError: (error) => {
        not.emit({ type: 'error', message: error.message });
      },
    }));
  }
  const monitorProgress = useMemo(() => {
    if (taskStatus?.data) {
      const stat = taskStatus?.data?.[taskStatus?.data?.length - 1]?.match(/(\d+)\/(\d+)/);
      return [stat?.[1] || 0, stat?.[2] || 0];
    }
    return [0, 0];
  }, [taskStatus]);

  return (
    <div>
      <div className="flex gap-3 mb-3">
        <div className="w-full">
          <Form.Item $first $dense>
            <Form.Item.Label>Anzahl</Form.Item.Label>
            <Input label="Anzahl" type="number" value={count} onChange={(e: any) => setCount(+e.target.value)} />
          </Form.Item>
        </div>
        <div className="w-full">
          <Form.Item $first $dense>
            <Form.Item.Label>Code Präfix</Form.Item.Label>
            {random ? (
              <Input label="Code" value={code} onChange={(e: any) => setCode(e.target.value)} type="text" />
            ) : (
              <>
                <Input
                  label="Code"
                  disabled
                  value={presetCode}
                  onChange={(e: any) => setCode(e.target.value)}
                  type="text"
                />
                <small>Randomisierte Codes sind deaktiviert</small>
              </>
            )}
          </Form.Item>
        </div>
      </div>

      {monitorProgress[1] > 0 && (
        <div className="my-6">
          <span className="text-md mb-2 flex gap-3 items-center">
            {monitorProgress[0]} von {monitorProgress[1]} Codes angelegt <Spinner />
          </span>
          <Progress max={monitorProgress[1] || 0} value={monitorProgress[0] || 0} />
        </div>
      )}

      <div className="flex justify-end mt-3">
        <Button onClick={() => addCodes()} loading={taskStatus?.data} $primary $disabled={random && codes.length === 0}>
          Codes anlegen
        </Button>
      </div>
    </div>
  );
}

export function DeleteModal({ open, onClose }) {
  const { voucherID: discountID } = useParams();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const notifications = useNotifications();
  const navigate = useNavigate();
  const { api } = useSdk();
  const { taskStatus, monitor, setSettings } = useTaskService();

  console.log(taskStatus.data, monitor.data);

  const monitorProgress = useMemo(() => {
    if (taskStatus?.data) {
      const stat = taskStatus?.data?.[taskStatus?.data?.length - 1]?.match(/(\d+)\/(\d+)/);
      return [stat?.[1] || 0, stat?.[2] || 0];
    }
    return [0, 0];
  }, [taskStatus]);

  async function deleteAll() {
    setIsDeleting(true);
    setSettings({
      url: 'hec.discount-delete',
      params: {
        shortID: api.shortID,
        refreshInterval: 1000,
        discountCampaignID: discountID,
      },
      callback: () => {
        notifications.emit({
          type: 'success',
          message: 'Codes erfolgreich gelöscht',
        });
        setIsDeleting(false);
        navigate('..');
      },
      onError: (res) => {
        const errorMessage = res.find((log) => log.toLowerCase().includes('error'));
        notifications.emit({
          type: 'error',
          message:
            errorMessage === 'Error: cannot_be_deleted_because_codes_were_used'
              ? 'Codes können nicht gelöscht werden, weil bereits einer eingelöst / exportiert wurden'
              : errorMessage,
        });

        onClose();
        setIsDeleting(false);
      },
    });
  }

  return (
    <ConfirmDelete
      isPending={isDeleting}
      title="Aktion mit allen Codes wirklich löschen?"
      deleteLabel="Löschen"
      onDelete={deleteAll}
      onClose={onClose}
      open={open}
    >
      {isDeleting ? (
        <div className="flex gap-3 items-center">
          {monitorProgress[1] > 0 && (
            <div className="my-6">
              <span className="text-md mb-2 flex gap-3 items-center">
                {monitorProgress[0]} von {monitorProgress[1]} Codes gelöscht <Spinner />
              </span>
              <Progress max={monitorProgress[1] || 0} value={monitorProgress[0] || 0} />
            </div>
          )}
        </div>
      ) : (
        'Diese Aktion kann nicht rückgängig gemacht werden'
      )}
    </ConfirmDelete>
  );
}
