import Spinner from '@/components/Spinner';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { AssetDropzone } from '../../components/AssetDropzone';

function AssetManagerDropzone({
  onDrop,
  uploading,
  textContent,
}: {
  onDrop: any;
  uploading: boolean;
  textContent?: string;
}) {
  return (
    <AssetDropzone onDrop={onDrop}>
      <label className=" cursor-pointer bg-white dark:bg-gray-600 border border-dashed border-gray-400 dark:border-gray-400 flex flex-col gap-y-4 items-center py-4 rounded-md text-gray-500 dark:text-gray-300">
        <input className="hidden" type="file" onChange={(e) => onDrop({ files: Array.from(e.target.files) })} />
        <CloudArrowUpIcon className="w-8 h-8" />
        <div className="flex flex-col space-y-1 text-center">
          {uploading ? (
            <div className="flex gap-2">
              <Spinner />
              <div>Dateien werden hochgeladen</div>
            </div>
          ) : (
            textContent || (
              <>
                <strong>Klicke um eine neue Datei hochzuladen</strong>
                <span className="text-xs">oder lege eine Datei auf dieser Fläche ab.</span>
              </>
            )
          )}
        </div>
      </label>
    </AssetDropzone>
  );
}
export default AssetManagerDropzone;
