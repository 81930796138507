import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import Searchbar from '../../components/Searchbar';
import Toggle from '../../components/Toggle';
import AssetManagerGallery from './AssetManagerGallery';
import { AssetStockSearch } from './AssetStockSearch';
import AssetTypeSelect from './AssetTypeSelect';
import SortDropdown from './SortDropdown';
import TileListSwitch from './TileListSwitch';

function AssetGroupManager({ manager, onClickAsset, stock = false }) {
  const [stockSearch, setStockSearch] = useState(false);

  if (stockSearch) {
    return (
      <AssetStockSearch
        onClose={(asset) => {
          if (asset) {
            onClickAsset?.(asset);
          }
          setStockSearch(false);
        }}
        {...manager}
      />
    );
  }

  return (
    <>
      <nav className="flex mt-2 justify-between items-center">
        <div className="flex flex-wrap gap-4 items-center">
          <Searchbar value={manager.query} onChange={manager.setQuery} placeholder="Dateiname oder Tag" />
          <AssetTypeSelect value={manager.type} onChange={manager.setType} />
          <Toggle label="Duplikate" value={manager.duplicates} onChange={manager.setDuplicates} />
        </div>
        <div className="flex flex-wrap gap-4 items-center justify-end">
          <SortDropdown value={manager.sort[0]} onChange={(s) => manager.setSort([s])} />
          <TileListSwitch value={manager.view} onChange={manager.setView} />
        </div>
      </nav>
      <AssetManagerGallery manager={manager} onClickAsset={(asset) => onClickAsset?.(asset)}>
        {stock && (
          <a
            onClick={() => setStockSearch(true)}
            className="cursor-pointer bg-white dark:bg-gray-600 border border-dashed border-gray-400 dark:border-gray-400 flex flex-col gap-y-4 items-center py-4 rounded-md text-gray-500 dark:text-gray-300"
          >
            <MagnifyingGlassCircleIcon className="w-8 h-8" />
            <div className="flex flex-col space-y-1 text-center">
              <strong>Kostenloses Stockfoto bei Unsplash suchen</strong>
              <span className="text-xs">und direkt einfügen.</span>
            </div>
          </a>
        )}
      </AssetManagerGallery>
    </>
  );
}
export default AssetGroupManager;
