import * as Sentry from '@sentry/react';
import { PublicAPI } from 'ec.sdk';
import { useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';
import environment from '../environment';
import useAccounts from './useAccounts';
import useShortID from './useShortID';

export interface UsePublicAPIProps {
  swrOptions?: any;
}

export function usePublicAPI(props: UsePublicAPIProps) {
  const { swrOptions } = props;
  const { shortID } = useShortID();

  const res = useSWRImmutable(
    shortID ? [shortID, environment.env] : null,
    () => {
      console.log('resolving PublicAPI');
      const api = new PublicAPI(shortID, environment.env, true);
      api.setClientID('rest');

      return api.resolve();
    },
    swrOptions,
  );

  useEffect(() => {
    res.error && console.error('error resolving PublicAPI', res.error);
  }, [res.error]);

  /* useEffect(() => {
    res.data && console.log('PublicAPI resolved', res.data);
  }, [res.data]); */
  return res;
}

/* export const PublicAPIProvider = (props: any) => {
  const { children, shortID, env = environment.env } = props;
  const { data: api } = usePublicAPI({ shortID, env });
  return <PublicAPIContext.Provider value={api}>{children}</PublicAPIContext.Provider>;
}; */

/* [markdown]
 * Exposes current sdk PublicAPI instance (.api) + legacy angular sdk (.sdk) instance.
 * ```js
 * const { api } = useSdk();
 * ```
 */

function useSdk() {
  const { shortID, isAppsite } = useShortID();

  useEffect(() => {
    if (shortID) {
      Sentry.setContext('appsite', {
        id: shortID,
      });
    }
  }, [shortID]);

  const { data: api, isLoading } = usePublicAPI({
    swrOptions: {
      retryOnError: false,
      onError: (err) => {
        console.warn(`error loading public api with shortID "${shortID}"`, err);
        window.location.href = '/';
      },
    },
  });

  const apiResolved = !isLoading && !!api;
  const { data: accounts } = useAccounts();
  return { api, isAppsite, apiResolved, accounts };
}
// test
export default useSdk;
