import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Input from '@/components/Input';
import { Pagination } from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import { useDSB } from '@/hooks/useDSB';
import { classNames } from '@/util/classNames';
import React, { useState } from 'react';

export function AssetStockSearch({ group: { assetGroupID }, list: { mutate }, onClose }) {
  const [pending, setPending] = useState(new Set());
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');

  const { swr, post } = useDSB();

  const { data, isLoading } = swr(
    'admin/photos',
    { page, query: query.length ? query : undefined },
    {
      keepPreviousData: true,
    },
  );

  return (
    <div className="my-2 space-y-2">
      <div className="flex gap-2">
        <Button onClick={() => onClose(null)}>zurück</Button>
        <Input.Debounced
          disabled={isLoading}
          value={query}
          placeholder="Suche nach Stockfotos…"
          onChange={({ target: { value } }) => {
            setPage(1);
            setQuery(value);
          }}
        />
      </div>
      {data?.items && !data.items.length ? (
        <Alert>Keine Ergebnisse gefunden…</Alert>
      ) : (
        <>
          <div className={classNames('grid grid-cols-7 gap-4 my-2', isLoading && 'animate-pulse')}>
            {data?.items.map((item) => (
              <a
                key={item.id}
                onClick={async () => {
                  try {
                    setPending((old) => {
                      old.add(item.id);
                      return new Set(old);
                    });
                    const asset = await post(`admin/photos`, {
                      assetGroupID,
                      url: item.url,
                      tags: [
                        item.user?.name && `name:${item.user.name}`,
                        item.user?.slug && `slug:${item.user.slug}`,
                        item.color && `color:${item.color}`,
                      ].filter(Boolean),
                    });
                    await mutate([...(data?.items || []), asset]);
                    onClose(asset);
                  } catch (e) {
                    console.error(e);
                  } finally {
                    setPending((old) => {
                      old.delete(item.id);
                      return new Set(old);
                    });
                  }
                }}
                className={classNames('relative', pending.has(item.id) && 'opacity-50 animate-pulse')}
              >
                {pending.has(item.id) && (
                  <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center z-10">
                    <Spinner className="!w-1/4 !h-1/4" />
                  </div>
                )}
                <img src={item.thumbnail} alt={item.title} className="aspect-1 object-cover rounded-lg" />
              </a>
            ))}
          </div>
          <Pagination value={page} onChange={setPage} total={data?.total} count={28} />
        </>
      )}
    </div>
  );
}
