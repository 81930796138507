import useSWR from 'swr';
import { useDSB } from './useDSB';
import useSettings from './useSettings';

export default function useIsMigrating() {
  const { data: settings } = useSettings();
  const seoTitle = settings?.appsiteUrl.split('/').pop();
  const { dsbApi } = useDSB();

  return useSWR(`${seoTitle}/isMigration`, async () => {
    const {
      items: [entry],
    } = await dsbApi.entryList('ho_migrate', { franchise_name: seoTitle });

    return entry?.state ?? 'notStared';
  });
}
