import React from 'react';
import { classNames } from '../util/classNames';
import Layout from './Layout';
import { useOidc } from '@axa-fr/react-oidc';
import Button from './Button';
import Alert from './Alert';

const AuthError = () => {
  const { login } = useOidc();
  return (
    // bg-gray-200 dark:bg-gray-600
    <Layout hideSideMenu skeleton>
      <div className="flex items-center justify-center flex-col h-screen gap-3">
        <div className="flex bg-indigo-200 items-center rounded-md text-indigo-700 flex-col gap- p-6">
          <div className="text-lg font-bold pb-0">Session abgelaufen</div>

          <div className="mb-6">Bitte melde dich erneut an</div>
          <Button $primary onClick={() => login(null)}>
            Zum Login
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default AuthError;
