import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import useAssetGroup from '../../hooks/useAssetGroup';
import AssetGroupManager from './AssetGroupManager';
import useAssetManager from './useAssetManager';

function AssetPickerModal({
  assetGroupID,
  open,
  onClose,
  onChange,
  value,
  solo,
  type = '',
  view,
  stock = false,
}: {
  assetGroupID: string;
  open?: boolean;
  onClose: (manager?: any) => void;
  onChange: (value: string[]) => void;
  value: string[];
  solo?: boolean;
  type?: 'image' | 'video' | '';
  view?: 'tiles' | 'list';
  stock?: boolean;
}) {
  // TODO: only execute this bit if modal is open?
  const manager = useAssetManager({
    mode: 'bulkSelect',
    selectedAssetIDs: value,
    defaultType: type,
    onBulkSelect: (assetIDs) => {
      onChange(assetIDs);
      onClose();
    },
  });

  const { data: assetGroup } = useAssetGroup({ assetGroupID });

  useEffect(() => {
    if (open) {
      if (view) {
        manager.setView(view);
      }

      if (assetGroup) {
        manager.setGroup(assetGroup);
      }

      if (value?.length) {
        manager.selection.replace(value.map((a: any) => a?.assetID ?? a).filter(Boolean));
      }
    }
  }, [open]);

  // value ?
  return (
    <Modal open={open} onClose={() => onClose(manager)}>
      <div className="w-full 2xl:w-[1300px]">
        <div className="flex justify-between items-center mb-4">
          <span className="m-0 text-2xl">Datei{!solo && 'en'} auswählen</span>
          <Button.Action
            onClick={() => {
              onClose(manager);
            }}
          >
            <XMarkIcon className="w-5 h-5" />
          </Button.Action>
        </div>
        <AssetGroupManager
          stock={stock}
          manager={manager}
          onClickAsset={(asset) => {
            if (solo) {
              if (type !== '' && asset.type !== type) {
                alert('Bitte wähle anderes Format wählen aus');
                return;
              }
              onChange([asset.assetID]);
              onClose(manager);
            } else {
              manager.selection.toggle(asset.assetID);
            }
          }}
        />
      </div>
    </Modal>
  );
}
export default AssetPickerModal;
