import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import { getHumanReadableAboDefinition } from '@entrecode/hectorone-helpers';
import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { useContext, useState } from 'react';
import { useGlobalContractPreview } from '../GlobalContract/useGlobalContractPreview';
import Modal from '../Modal';

export function usePDFPreview(type = 'GlobalContract') {
  const { membershipTemplate, selectedVersion } = useContext(OMTContext);

  const { generatePreview, pending: previewPending } = useGlobalContractPreview(type);
  const [preview, setPreview] = useState(null);

  async function handlePreview() {
    if (preview) {
      URL.revokeObjectURL(preview);
    }

    await generatePreview(
      {
        [type === 'GlobalAddon' ? 'addon' : 'contract']: {
          name: membershipTemplate?.name,
          pricePerInterval: selectedVersion.mainIntervalPrice,
          mainIntervalPrice: selectedVersion.mainIntervalPrice,
          interval: selectedVersion.mainInterval,
          mainInterval: selectedVersion.mainInterval,
          duration: selectedVersion.mainDuration,
          trialPeriod: selectedVersion.trialPeriod,
          discountDuration: selectedVersion.discountDuration,
          discountInterval: selectedVersion.discountInterval,
          discountIntervalPrice: selectedVersion.discountIntervalPrice,
          mainText: selectedVersion.mainText,
          begin: selectedVersion.startDate,
          salesDescription: membershipTemplate?.salesDescription,
          description: membershipTemplate?.description,
          includedServices: selectedVersion.hectorSettings?.addonIncludedServices ?? null,
        },
        consumerism: getHumanReadableAboDefinition(selectedVersion),
        addons: selectedVersion.addons,
        discounts:
          selectedVersion.discounts?.map((d) => {
            d.price = -d.price;
            return d;
          }) ?? [],
        fees: selectedVersion.fees,
        isGlobalTemplate: selectedVersion?.contractTemplates[0]?.type === 'global_contract',
        templateName: selectedVersion?.contractTemplates[0]?.name,
        modified: selectedVersion?.contractTemplates[0]?.modified,
      },
      false,
      setPreview,
    );
  }

  return { handlePreview, previewPending, preview, setPreview };
}

export const PDFPreviewModal = ({ state }) => {
  const [preview, setPreview] = state;
  return (
    <Modal
      className="w-full max-w-[80vw] h-full"
      open={preview !== null}
      onClose={() => {
        URL.revokeObjectURL(preview);
        setPreview(null);
      }}
    >
      <>
        <div className="w-full flex justify-end mb-5">
          <XMarkIcon
            className="w-6 h-6 cursor-pointer"
            onClick={() => {
              URL.revokeObjectURL(preview);
              setPreview(null);
            }}
          />
        </div>
        <object data={preview} type="application/pdf" width="100%" height="800px">
          <p>
            Unable to display PDF file.{' '}
            <a href="/uploads/media/default/0001/01/540cb75550adf33f281f29132dddd14fded85bfc.pdf">Download</a> instead.
          </p>
        </object>
      </>
    </Modal>
  );
};
