export default function currency(value) {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
}

/** transform euro to cent
 * should also handle strange js numbers like 19.90 * 100 != 1989.9999999999998
 * */
export const euroToCent = (amount: number): number => {
  if ([undefined, null].includes(amount)) {
    return null;
  }

  return Math.round(amount * 100);
};
