import useSettings from '@/hooks/useSettings';
import { price } from '@/routes/OnlineMembershipTemplateDetail';
import { classNames } from '@/util/classNames';
import { MembershipTemplate } from '@entrecode/dm-types/types/MembershipTemplate';
import { MembershipTemplateVersion } from '@entrecode/dm-types/types/MembershipTemplateVersion';
import { formatPeriodUnit, getConsumerProtection, getHumanReadableAboDefinition } from '@entrecode/hectorone-helpers';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/solid';
import html2canvas from 'html2canvas';
import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Modal from '../Modal';
import Spinner from '../Spinner';
import CommentPreview from './CommentPreview';

interface WebPreviewProps {
  membershipTemplate: MembershipTemplate;
  selectedVersion: MembershipTemplateVersion;
  open: boolean;
}

export const Html2Image = ({ children, entry, name, disabled }) => {
  const [image, setImage] = useState(null);
  const ref = useRef(null);

  const render = useCallback(async () => {
    if (!image) {
      const canvas = await html2canvas(ref.current, {
        useCORS: true,
        scale: 0.8,
        width: ref.current.clientWidth,
      });
      setImage(canvas.toDataURL('image/jpg', 1));
    } else {
      setImage(null);
    }
  }, [image]);

  if (disabled) return children;

  return (
    <div className="relative overflow-clip">
      {!image && (
        <Button.Action onClick={render} tooltip="Korrektur" placement="left" className="absolute top-2 right-2 z-10">
          <PencilIcon className="w-5 h-5" />
        </Button.Action>
      )}
      {!image && (
        <div ref={ref} className={classNames('w-full h-auto')}>
          {children}
        </div>
      )}
      {image && <CommentPreview image={image} section="price" name={name} entry={entry} onComment={render} />}
    </div>
  );
};

export const WebPreview = ({ open, membershipTemplate, selectedVersion }: WebPreviewProps) => {
  const [_search, setSearch] = useSearchParams();
  const { data: settings } = useSettings();

  const [activeAccordion, setActiveAccordion] = useState<Set<number>>(new Set());

  const templateName = useMemo(() => {
    let l = membershipTemplate?.name;

    for (const { name } of selectedVersion?.discounts ?? []) {
      l = `${l} + ${name}`;
    }

    return l;
  }, [membershipTemplate?.name, selectedVersion?.discounts]);

  const totalPrice = useMemo(() => {
    if (
      !Number.isInteger(selectedVersion?.discountIntervalPrice) &&
      !Number.isInteger(selectedVersion?.initialIntervalPrice)
    ) {
      return selectedVersion?.mainIntervalPrice ?? 0;
    }

    const p = selectedVersion?.discounts?.reduce((a, b) => a + b.price, 0);

    return (selectedVersion?.discountIntervalPrice ?? selectedVersion?.initialIntervalPrice ?? 0) - (p ?? 0);
  }, [selectedVersion]);

  const originalPrice = useMemo(() => selectedVersion?.mainIntervalPrice ?? 0, [selectedVersion]);

  if (!settings) return <Spinner />;

  const styles = {
    '--container-background-color': settings.styles.mw?.containerBackgroundColor,
    '--container-text-color': settings.styles.mw?.containerTextColor,
    '--footer-background-color': settings.styles.mw?.footerBackgroundColor,
    '--footer-text-color': settings.styles.mw?.footerTextColor,
    '--price-color': settings.styles.mw?.priceColor,
    '--headline-color': settings.styles.mw?.headlineColor,
    '--card-background-color': settings.styles.mw?.cardBackgroundColor,
    '--card-text-color': settings.styles.mw?.cardTextColor,
    '--card-active-background-color': settings.styles.mw?.cardActiveBackgroundColor,
    '--card-active-text-color': settings.styles.mw?.cardActiveTextColor,
    '--form-background-color': settings.styles.mw?.formBackgroundColor,
    '--form-text-color': settings.styles.mw?.formTextColor,
    '--form-placeholder-color': settings.styles.mw?.formPlaceholderColor,
    '--primary-button-background-color': settings.styles.mw?.primaryButtonBackgroundColor,
    '--primary-button-text-color': settings.styles.mw?.primaryButtonTextColor,
    '--secondary-button-background-color': settings.styles.mw?.secondaryButtonBackgroundColor,
    '--secondary-button-text-color': settings.styles.mw?.secondaryButtonTextColor,
  };

  return (
    <Modal
      open={open}
      onClose={() =>
        setSearch((prev) => {
          prev.delete('webpreview');
          return prev;
        })
      }
      className="w-full max-w-[80%]"
    >
      <div className="flex justify-between mb-6">
        <div>Web Vorschau</div>
        <Button.Action
          onClick={() =>
            setSearch((prev) => {
              prev.delete('webpreview');
              return prev;
            })
          }
        >
          <XMarkIcon className="w-5 h-5" />
        </Button.Action>
      </div>
      <div className="mx-auto rounded-lg w-full">
        {settings && membershipTemplate && selectedVersion ? (
          <>
            <div className="w-full border h-11 relative rounded-t-lg bg-white dark:bg-gray-800 border-b flex overflow-hidden justify-start items-center space-x-1.5 px-2">
              Vorteile
            </div>

            <Html2Image disabled={selectedVersion.draftStatus === null} entry={selectedVersion} name="vorteile">
              <div
                className="relative w-full min-h-96 border-t-0 "
                style={{
                  background: settings.styles?.backgroundColor,
                  color: settings.styles?.textColor,
                  ...styles,
                }}
              >
                <div className="flex bg-[--container-background-color] p-5 flex-col gap-3">
                  <h1 className="font-bold text-3xl text-center text-[--headline-color]">
                    Diese Super-Vorteile bietet Dir "Club Name"
                  </h1>
                  <div className="rounded overflow-hidden text-white mb-6">
                    {selectedVersion?.benefits?.map((benefit, i) => (
                      <div
                        key={i}
                        className={classNames(
                          i % 2 === 0
                            ? 'bg-[--card-background-color] text-[--card-text-color]'
                            : 'bg-[--card-active-background-color] text-[--card-active-text-color]',
                          'cursor-pointer p-3',
                        )}
                        onClick={() =>
                          setActiveAccordion((old) => {
                            if (old.has(i)) {
                              old.delete(i);
                            } else {
                              old.add(i);
                            }
                            return new Set([...old]);
                          })
                        }
                      >
                        <div className="flex gap-5 items-center p-4">
                          <CheckCircleIcon className="w-7 h-7 " />
                          <div className="text-2xl font-bold">{benefit.title}</div>

                          {benefit.description && (
                            <div className="flex grow justify-end">
                              <ChevronDownIcon
                                className={classNames('w-7 h-7 text-white', activeAccordion.has(i) && 'rotate-180')}
                              />
                            </div>
                          )}
                        </div>
                        {benefit.description && activeAccordion.has(i) && (
                          <div className="p-4 pt-0">
                            <p>{benefit.description}</p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Html2Image>

            <div className="w-full h-11 mt-6 border relative rounded-t-lg bg-white dark:bg-gray-800 border-b flex overflow-hidden justify-start items-center space-x-1.5 px-2">
              Preis
            </div>

            <Html2Image disabled={selectedVersion.draftStatus === null} entry={selectedVersion} name="preis">
              <div
                className="relative  w-full min-h-96 border-t-0 "
                style={{
                  background: settings.styles?.backgroundColor,
                  color: settings.styles?.textColor,
                  borderColor: settings.styles?.textColor,
                  ...styles,
                }}
              >
                <div className="bg-[--container-background-color] text-[--container-text-color] p-4 py-16 flex flex-col gap-4 items-center">
                  <div className="text-center flex flex-col gap-3">
                    <div className="uppercase font-semibold text-sm">{templateName}</div>
                    {totalPrice !== originalPrice ? (
                      <>
                        <div className="text-4xl font-black ">{price(totalPrice)}</div>
                        <div className="text-xl font-black relative line-through">{price(originalPrice)}</div>
                      </>
                    ) : (
                      <div className="text-4xl font-black">{price(totalPrice)}</div>
                    )}
                  </div>
                  <div className="border-t uppercase border-t-current mt-5 pt-3">
                    {formatPeriodUnit(selectedVersion.mainInterval)}
                  </div>
                  {selectedVersion?.discounts?.map((d) => {
                    return (
                      <div
                        key={d.id}
                        className="flex items-center justify-center bg-[--card-active-background-color] text-[--card-active-text-color] w-1/2 p-4 rounded"
                      >
                        <div className="flex flex-row items-center gap-4">
                          <Checkbox checked disabled />
                          <div>
                            <div className="text-lg font-semibold -mb-1">{d.name}</div>
                            <div className="text-sm">-{price(d.price)}</div>
                            <p className="text-xs">{d.description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Html2Image>

            <div className="w-full h-11 mt-6 border relative rounded-t-lg bg-white dark:bg-gray-800 border-b flex overflow-hidden justify-start items-center space-x-1.5 px-2">
              Buchungsübersicht
            </div>

            <Html2Image disabled={selectedVersion.draftStatus === null} entry={selectedVersion} name="overview">
              <div
                className="relative  w-full min-h-96 border-t-0 "
                style={{
                  background: settings.styles?.backgroundColor,
                  color: settings.styles?.textColor,
                  ...styles,
                }}
              >
                <div className="flex p-5 bg-[--container-background-color] text-[--container-text-color] gap-3">
                  <div className="w-full">
                    <div className="text-xl uppercase font-bold ">Dein Vertrag</div>
                    {[
                      { name: 'Gewählter Vertrag', val: templateName },
                      { name: 'Dein Club', val: 'Muster Club' },
                      { name: 'Gesellschaft', val: 'Muster Gesellschaft' },
                      { name: 'USt-IdNr.', val: '1244556 1234' },
                    ].map(({ name, val }) => (
                      <div key={name} className="mt-5">
                        <div className="text-sm">{name}</div>
                        <div className="">{val}</div>
                      </div>
                    ))}
                  </div>
                  <div className="w-full">
                    <div className="mb-8">
                      <div className="text-xs">Laufzeit und Kündigung</div>
                      <div className="text-sm">{getHumanReadableAboDefinition(selectedVersion)}</div>
                    </div>
                    <div>
                      {getConsumerProtection(selectedVersion).map(({ title, terms }, i) => {
                        return (
                          <div key={i}>
                            <div className="text-sm opacity-65 mt-3 mb-1">{i === 0 ? templateName : title}</div>
                            <table className="w-full text-xs">
                              {Object.entries(terms).map(([key, val], j) => {
                                if (!val) {
                                  return null;
                                }

                                return (
                                  <Fragment key={j}>
                                    <tr className="border-t-4 border-t-transparent">
                                      <td className="w-1/2">
                                        <span className="font-bold">{key}</span>
                                      </td>
                                      <td className="w-1/2">{val}</td>
                                    </tr>
                                    {i === 0 &&
                                      key === 'Regulärer Preis' &&
                                      selectedVersion.discounts?.map(({ name, price: p }) => {
                                        return (
                                          <tr key={name} className="text-[10px]">
                                            <td className="w-1/2">
                                              <span className="font-bold ml-2.5">{name}</span>
                                            </td>
                                            <td className="w-1/2">
                                              -{price(p)} / {val.split(' / ').pop()}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </Fragment>
                                );
                              })}
                            </table>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Html2Image>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </Modal>
  );
};
