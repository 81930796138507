import useSWR from 'swr';
import useSdk from './useSdk';

export const useGlobalContract = (name = 'GlobalContract') => {
  const { api } = useSdk();

  return useSWR(
    api && name ? `globalContract/${name}` : null,
    async () => {
      try {
        const {
          items: [contract],
        } = await api.entryList('eva_template', { type: 'global_contract', name });
        if (!contract) {
          throw new Error(`${name} not found`);
        }
        return contract;
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    { revalidateOnFocus: false },
  );
};
