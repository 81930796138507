import { useGeneralMembershipTemplates } from '@/hooks/useAppsite';
import React, { Fragment, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import Badge from '@/components/Badge';
import Button from '@/components/Button';
import Form from '@/components/Form';
import Spinner from '@/components/Spinner';
import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import { ReadyStateContext } from '@/hooks/onlineMembershipTemplates/useReadyState';
import { useDSB } from '@/hooks/useDSB';
import useFeedback from '@/hooks/useFeedback';
import { TabsContext } from '@/routes/OnlineMembershipTemplateDetail';
import { classNames } from '@/util/classNames';
import currency from '@/util/currency';
import { MembershipTemplateVersion } from '@entrecode/dm-types/types/MembershipTemplateVersion';
import { formatPeriod, formatRecurring } from '@entrecode/hectorone-helpers';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Control, Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import Input from '../Input';
import Modal from '../Modal';
import SelectInput from '../SelectInput';
import { ToggleInput } from '../Toggle';
import Tooly from '../Tooly';

export function HectorForm() {
  const { membershipTemplate, mutate, completeState, changeStatus, selectedVersion } = useContext(OMTContext);
  const [search, setSearch] = useSearchParams();

  const { canNext } = useContext(ReadyStateContext) as any;
  const readyForTest =
    completeState.hector &&
    completeState.prices &&
    completeState.templates &&
    canNext &&
    search.has('saved') &&
    selectedVersion.draftStatus === 'In Vorbereitung';
  const [showModal, setShowModal] = useState(readyForTest);

  useEffect(() => {
    setShowModal(readyForTest);
  }, [readyForTest]);

  if (!(membershipTemplate && selectedVersion)) return <Spinner />;
  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="flex flex-wrap items-center gap-3  justify-between mb-3">
          <span className="">
            Super, Du hast das Abo fertig konfiguriert! Möchtest Du du nun den internen Test anfordern?
          </span>
          <Button.Action onClick={() => setShowModal(false)}>
            <XMarkIcon className="w-4 h-4" />
          </Button.Action>
        </div>
        <div className="flex justify-end gap-3">
          <Button
            $primary
            onClick={() => {
              setSearch((prev) => {
                prev.delete('saved');
                prev.set('tab', '5');
                return prev;
              });
              changeStatus('Im Test');
              setShowModal(false);
            }}
          >
            Ja, internen Test anfordern
          </Button>
          <Button
            $secondary
            onClick={() => {
              setSearch((prev) => {
                prev.delete('saved');
                return prev;
              });
              setShowModal(false);
            }}
          >
            Nein, Abo-Anlage weiter bearbeiten
          </Button>
        </div>
      </Modal>

      <Template mutate={mutate} selectedVersion={selectedVersion} />
    </>
  );
}

function Template({ selectedVersion, mutate }) {
  const { userCan, membershipTemplate, isAddon } = useContext(OMTContext);
  const { data: generalTemplates } = useGeneralMembershipTemplates(membershipTemplate?.backendId);

  const form = useForm({
    defaultValues: {
      ...selectedVersion,
      hectorSettings: {
        ...selectedVersion.hectorSettings,
        memberStatus: Number.isInteger(selectedVersion.hectorSettings?.memberStatus)
          ? String(selectedVersion.hectorSettings?.memberStatus)
          : selectedVersion.hectorSettings?.memberStatus,
      },
    } as MembershipTemplateVersion,
    mode: 'onChange',
  });

  const { setCanChange } = useContext(TabsContext);
  const isDirty = form.formState.isDirty;
  useEffect(() => {
    setCanChange(!isDirty);
  }, [isDirty, setCanChange]);

  const [, setSearch] = useSearchParams();
  const { withFeedback, pending } = useFeedback();
  const onSubmit = (values) => {
    return withFeedback(
      async () => {
        selectedVersion.mainAbo = values.mainAbo;
        selectedVersion.abosParallel = values.abosParallel;
        selectedVersion.abosAfterTrial = values.abosAfterTrial;
        selectedVersion.abosAtEnd = values.abosAtEnd;
        selectedVersion.hectorSettings = values.hectorSettings;
        await selectedVersion.save();

        setSearch((prev) => {
          prev.set('saved', 'true');
          return prev;
        });

        await mutate();

        // form.reset({ ...selectedVersion } as MembershipTemplateVersion);
        setCanChange(true);
      },
      {
        success: 'Änderungen gespeichert',
        error: 'Änderungen konnten nicht gespeichert werden',
      },
    );
  };

  const { mainContracts, otherContracts } = useMemo(() => {
    if (!generalTemplates) return { mainContracts: [], otherContracts: [] };
    return {
      mainContracts: generalTemplates.filter((c) => c.isMainContract),
      otherContracts: generalTemplates.filter((c) => !c.isMainContract),
    };
  }, [generalTemplates]);

  // Wenn MainAbo kein Renewal (nicht automatisch verlängert)
  // -> abosAtEnd only {isMainContract: true} && mind 1 muss gewählt werden
  // else
  // -> abosAtEnd only {isMainContract: false} && optional

  const mainAbo = form.watch('mainAbo');

  const { get } = useDSB();
  const { data: userRoles } = useSWR(
    membershipTemplate.backendId ? 'dsb/userRoles' : null,
    async () => {
      return get(`config/customerStatus?backendId=${membershipTemplate?.backendId}`);
    },
    {
      fallbackData: [],
    },
  );

  const abosAtEnd = useMemo(() => {
    if (!mainAbo) return [''];
    const mainContract = generalTemplates?.find((c) => c.id === mainAbo?.[0]?.templateId);
    return mainContract?.renewal === '' ? mainContracts : otherContracts;
  }, [mainAbo, mainContracts, otherContracts, generalTemplates]);

  return (
    <div
      className={classNames(
        'bg-white dark:bg-gray-700 rounded-lg p-4',
        (!userCan.edit || selectedVersion.draftStatus === null) && ' pointer-events-none',
      )}
    >
      <Form>
        {isAddon ? (
          <Form.Item $first>
            <Form.Item.Label>Hector Vertragsvorlage</Form.Item.Label>

            <Form.Item.Body>
              <MultiSelectInput
                placeholder="Abo wählen"
                control={form.control}
                rules={{ required: true }}
                limit={1}
                name="mainAbo"
                badgeLabel={(item) => {
                  const contract = generalTemplates?.find(
                    ({ id, type }) => id === item.templateId && type === item.templateType,
                  );
                  return (
                    <div className="inline-flex flex-col p-2">
                      <strong>{contract?.name}</strong>
                      <small>Testphase: {contract?.freeTestingPhase || '-'}</small>
                      <small>
                        Preis: {contract?.pricePerInterval || '0'} € / {formatRecurring(contract?.interval) || '-'}
                      </small>
                    </div>
                  );
                }}
                // badgeLabel={(item) => {
                //   return mainContracts.find((i) => i.id === item.templateId)?.name;
                // }}
                transformItem={(item) => ({
                  templateId: item.value.id,
                  templateType: item.value.type,
                  conditions: null,
                })}
                items={generalTemplates?.map((template) => ({
                  label: `${template.name} ${template.renewal ? ' (automatisch verlängert)' : ''}`,
                  value: template,
                }))}
              />
            </Form.Item.Body>
          </Form.Item>
        ) : (
          <>
            <Form.Item $first>
              <Form.Item.Label>Hauptabo</Form.Item.Label>
              {/* TODO neue api nutzen*/}

              <Form.Item.Body>
                <MultiSelectInput
                  placeholder="Abo wählen"
                  control={form.control}
                  rules={{ required: true }}
                  limit={1}
                  name="mainAbo"
                  badgeLabel={(item) => {
                    const contract = generalTemplates?.find(
                      ({ id, type }) => id === item.templateId && type === item.templateType,
                    );
                    return (
                      <div className="inline-flex flex-col p-2">
                        {!contract?.isMainContract && <span className="mb-3 text-red-500">Achtung! Kein Hauptabo</span>}
                        <strong>
                          {contract?.name} <small className="text-[10px] font-mono">{contract?.type}</small>
                        </strong>
                        <small>
                          Testphase:{' '}
                          {contract?.freeTestingPhase ? formatPeriod(`P${contract?.freeTestingPhase}D`) : '-'}
                        </small>
                        <small>
                          Preis: {currency(contract?.pricePerInterval ?? 0)} /{' '}
                          {formatRecurring(contract?.interval) || '-'}
                        </small>
                      </div>
                    );
                  }}
                  // badgeLabel={(item) => {
                  //   return mainContracts.find((i) => i.id === item.templateId)?.name;
                  // }}
                  transformItem={(item) => ({
                    templateId: item.value.id,
                    templateType: item.value.type,
                    conditions: null,
                  })}
                  items={mainContracts.map((template) => ({
                    label: `${template.name} ${template.renewal ? ' (automatisch verlängert)' : ''}`,
                    value: template,
                  }))}
                />
              </Form.Item.Body>
            </Form.Item>

            <Form.Item>
              <Form.Item.Label>Parallel zu buchende Nebenabos</Form.Item.Label>
              {/* TODO neue api nutzen*/}
              <Form.Item.Body>
                <MultiSelectInput
                  placeholder="Abo wählen"
                  control={form.control}
                  name="abosParallel"
                  // badgeLabel={(item) => {
                  //   return otherContracts.find((i) => i.id === item.templateId)?.name;
                  // }}
                  badgeLabel={(item) => {
                    const contract = otherContracts?.find(
                      ({ id, type }) => id === item.templateId && type === item.templateType,
                    );
                    return (
                      <div className="inline-flex flex-col p-2">
                        <strong>
                          {contract?.name} <small className="text-[10px] font-mono">{contract?.type}</small>
                        </strong>
                        <small>
                          Testphase:{' '}
                          {contract?.freeTestingPhase ? formatPeriod(`P${contract?.freeTestingPhase}D`) : '-'}
                        </small>
                        <small>
                          Preis: {currency(contract?.pricePerInterval ?? 0)} /{' '}
                          {formatRecurring(contract?.interval) || '-'}
                        </small>
                      </div>
                    );
                  }}
                  transformItem={(item) => ({
                    templateId: item.value.id,
                    templateType: item.value.type,
                    conditions: null,
                  })}
                  items={otherContracts.map((template) => ({
                    label: template.name,
                    value: template,
                  }))}
                />
                <small>Hier können ausschließlich Nebenabos gewählt werden.</small>
              </Form.Item.Body>
            </Form.Item>

            <Form.Item>
              <Form.Item.Label>Nach Testphase zu buchende Nebenabos</Form.Item.Label>
              {/* TODO neue api nutzen*/}
              <Form.Item.Body>
                <MultiSelectInput
                  placeholder="Abo wählen"
                  control={form.control}
                  name="abosAfterTrial"
                  badgeLabel={(item) => {
                    const contract = otherContracts.find(
                      ({ id, type }) => id === item.templateId && type === item.templateType,
                    );
                    return (
                      <div className="inline-flex flex-col p-2">
                        <strong>
                          {contract?.name} <small className="text-[10px] font-mono">{contract?.type}</small>
                        </strong>
                        <small>
                          Testphase:{' '}
                          {contract?.freeTestingPhase ? formatPeriod(`P${contract?.freeTestingPhase}D`) : '-'}
                        </small>
                        <small>
                          Preis: {currency(contract?.pricePerInterval ?? 0)} /{' '}
                          {formatRecurring(contract?.interval) || '-'}
                        </small>
                      </div>
                    );
                  }}
                  transformItem={(item) => ({
                    templateId: item.value.id,
                    templateType: item.value.type,
                    conditions: null,
                  })}
                  items={otherContracts.map((template) => ({
                    label: template.name,
                    value: template,
                  }))}
                />
                <small>
                  Hier können ausschließlich Nebenabos gewählt werden. Diese haben dann einen ZugangAb zum Ende der
                  Testphase des Hauptabos.{' '}
                </small>
              </Form.Item.Body>
            </Form.Item>

            <Form.Item>
              <Form.Item.Label>im Anschluss zu buchende Hector-Abovorlagen</Form.Item.Label>
              <Form.Item.Body>
                <MultiSelectInput
                  key={mainAbo?.[0]?.templateId}
                  placeholder="Abo wählen"
                  control={form.control}
                  name="abosAtEnd"
                  rules={
                    {
                      /*validate: {
                  required: (value) => {
                    const cont = generalTemplates?.find((g) => g.id === mainAbo?.[0]?.templateId);
                    return cont?.renewal !== '' ? true : value.length > 0 ? true : false;
                  },
                },*/
                    }
                  }
                  badgeLabel={(item) => {
                    const contract = generalTemplates?.find(
                      ({ id, type }) => id === item.templateId && type === item.templateType,
                    );
                    return (
                      <div className="inline-flex flex-col p-2">
                        <strong>
                          {contract?.name} <small className="text-[10px] font-mono">{contract?.type}</small>
                        </strong>
                        <small>
                          Testphase:{' '}
                          {contract?.freeTestingPhase ? formatPeriod(`P${contract?.freeTestingPhase}D`) : '-'}
                        </small>
                        <small>
                          Preis: {currency(contract?.pricePerInterval ?? 0)} /{' '}
                          {formatRecurring(contract?.interval) || '-'}
                        </small>
                      </div>
                    );
                  }}
                  // badgeLabel={(item) => {
                  //   return generalTemplates?.find((i) => i.id === item.templateId)?.name;
                  // }}
                  transformItem={(item) => ({
                    templateId: item.value.id,
                    templateType: item.value.type,
                    conditions: null,
                  })}
                  items={abosAtEnd?.map((template) => ({
                    label: `${template.name} ${template.isMainContract ? ' (Hauptabo)' : ''}`,
                    value: template,
                  }))}
                />
                <small>
                  Wenn das Hauptabo automatisch verlängert wird können hier nur Nebenabos gewählt werden. Sollte das
                  Hauptabo keine automatische Verlängerung haben, so muss zuerst ein Hauptabo ausgewält werden und im
                  Anschlus beliebig viele Nebenabos. Das gewählte Hauptabo wird dann als Anschlussabo angelegt.
                </small>
              </Form.Item.Body>
            </Form.Item>

            <Form.Item>
              <Form.Item.Label>Mitgliederstatus*</Form.Item.Label>
              <Form.Item.Body>
                <SelectInput
                  control={form.control}
                  name="hectorSettings.memberStatus"
                  rules={{ required: true }}
                  items={userRoles?.map((item) => ({
                    label: item.name,
                    value: item.id.split('$')[1],
                  }))}
                />
                {form.formState.errors.hectorSettings?.memberStatus && (
                  <small className="text-red-500">Pflichtfeld</small>
                )}
              </Form.Item.Body>
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Form.Item.Label>
            Checkinbemerkung{' '}
            <Tooly
              placement="right"
              label="Ist eine Checkinbemerkung eingetragen wird der Checkin automatisch angehalten."
            >
              <QuestionMarkCircleIcon className="w-4 h-4" />
            </Tooly>
          </Form.Item.Label>
          <Form.Item.Body>
            <Input type="text" {...form.register('hectorSettings.checkinNote')} />
            <small>Ist eine Checkinbemerkung eingetragen, wird der CheckIn automatisch angehalten</small>
          </Form.Item.Body>
        </Form.Item>

        <Form.Item $dense $first>
          <ToggleInput
            control={form.control}
            name="hectorSettings.supressRecruiterOnAdditionalTemplates"
            label="Werber-Provision bei Nebenabos unterdrücken"
          />
        </Form.Item>

        <Form.Item $dense $first>
          <ToggleInput control={form.control} name="hectorSettings.stopCheckinU18" label="CheckIn anhalten bei U18" />
        </Form.Item>

        {selectedVersion.draftStatus !== null && userCan.edit && (
          <div className="flex p-4 pb-6 bg-white dark:bg-gray-700 sticky bottom-0 justify-end">
            <Button $disabled={!isDirty} $primary loading={pending} onClick={form.handleSubmit(onSubmit)}>
              Speichern
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
}

function MultiSelectInput({
  control,
  name,
  limit,
  transformItem,
  badgeLabel,
  items,
  rules,
}: {
  control: Control<any>;
  transformItem: (item: any) => any;
  limit?: number;
  placeholder: string;
  name: string;
  rules?: any;
  badgeLabel: (item, items) => ReactNode;
  items: { label: string; value: number }[];
}) {
  const [selected, setSelected] = useState({ label: '', value: null });
  const [query, setQuery] = useState('');
  const filteredItems =
    query === ''
      ? items
      : items.filter((item) =>
          item.label.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        );

  return (
    <div>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, fieldState }) => {
          return (
            <div>
              <div className="flex flex-wrap gap-2 mb-3">
                {field.value?.map((item, index) => (
                  <Badge
                    className="rounded-md"
                    hasX
                    onX={() => field.onChange(field.value.filter((i) => i !== item))}
                    key={index}
                  >
                    {badgeLabel(item, items)}
                  </Badge>
                ))}
              </div>
              <div
                className={classNames(limit && field.value?.length >= limit ? 'opacity-50 pointer-events-none' : '')}
              >
                <Combobox
                  value={selected}
                  onChange={(e) => {
                    field.onChange([...(field.value || []), transformItem(items.find((i) => i.value === e.value))]);
                    setQuery('');
                  }}
                >
                  <div className="relative my-2 border rounded-lg ">
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                      <Combobox.Input
                        className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                        displayValue={(item: any) => item.label}
                        onChange={(event) => setQuery(event.target.value)}
                      />
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </Combobox.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      afterLeave={() => setQuery('')}
                    >
                      <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredItems?.length === 0 && query !== '' ? (
                          <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                            Keine Ergebnisse…
                          </div>
                        ) : (
                          filteredItems?.map((item, i) => (
                            <Combobox.Option
                              key={i}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                }`
                              }
                              value={item}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                    {item.label}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                        active ? 'text-white' : 'text-teal-600'
                                      }`}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Combobox.Option>
                          ))
                        )}
                      </Combobox.Options>
                    </Transition>
                  </div>
                </Combobox>
              </div>
              {fieldState.error && <small className="text-red-500 text-xs">Mind 1 Abo muss gewählt werden</small>}
            </div>
          );
        }}
      />
    </div>
  );
}
/*
  <Form.Item $dense $first>
            <Form.Item.Label>
              Checkinbemerkung
              <Tooly label="Ist eine Checkinbemerkung eingetragen wird der Checkin automatisch angehalten.">
                <QuestionMarkCircleIcon className="w-4 h-4" />
              </Tooly>
            </Form.Item.Label>
            <Input type="text" {...form.register("hectorSettings.checkinNote")} />
            <small>
              Ist eine Checkinbemerkung eingetragen, wird der CheckIn automatisch
              angehalten
            </small>
          </Form.Item>

          <Form.Item $dense $first>
            <ToggleInput
              control={form.control}
              name="hectorSettings.supressRecruiterOnAdditionalTemplates"
              label="Werber-Provision bei Nebenabos unterdrücken"
            />
          </Form.Item>

          <Form.Item $dense $first>
            <ToggleInput
              control={form.control}
              name="hectorSettings.stopCheckinU18"
              label="CheckIn anhalten bei U18"
            />
          </Form.Item>

          <Form.Item $dense $first>
            <Form.Item.Label>Mitgliederstatus</Form.Item.Label>
            <SelectInput
              control={form.control}
              name="memberStatus"
              items={[{ label: "Mitglied", value: 1 }]}
            />
          </Form.Item>
*/
