import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import useFeedback from '@/hooks/useFeedback';
import useSdk from '@/hooks/useSdk';
import { DeleteModal } from '@/routes/Discounts/components/CodesTable';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from '../Badge';
import Button from '../Button';
import ConfirmDelete from '../ConfirmDelete';

export const DeleteButton = ({ selectedVersion }) => {
  const { membershipTemplate, mutate, setSelectedVersion } = useContext(OMTContext);
  const { withFeedback, pending } = useFeedback();
  const navigate = useNavigate();
  const [safeDelete, setSafeDelete] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { api } = useSdk();

  function archive() {
    withFeedback(
      async () => {
        membershipTemplate.active = null;
        await membershipTemplate.save();
        setSafeDelete(false);
      },
      {
        success: 'Version archiviert',
        error: 'Version konnte nicht archiviert werden',
      },
    );
  }

  function deleteVersion() {
    // if (membershipTemplate.type === 'contract' || membershipTemplate.type === 'addon') {
    //   setShowDeleteModal(true);
    //   return;
    // }
    withFeedback(
      async () => {
        membershipTemplate.versions = membershipTemplate.versions.filter((v) => v.id !== selectedVersion.id);
        const versionTest = (
          await api.entryList('membership_template_version_test', {
            membershipTemplateVersion: selectedVersion.id,
          })
        ).getAllItems();

        for (const test of versionTest) {
          console.log('delelting test', test);
          await test.delete();
        }

        membershipTemplate.draft = null;
        await (selectedVersion as any).delete();

        await mutate(await membershipTemplate.save());
        if (membershipTemplate.active) {
          if (membershipTemplate.versions.length === 0) {
            console.log(
              '%c Abo hat active Version, die nicht in Versions zugewiesen sind. Bitte im DM fixen (wurde wahrsch. falsch importiert)',
              'color: red; font-weight: bold; background-color: yellow;',
            );
          }
          setSelectedVersion(membershipTemplate.active.id);
          setSafeDelete(false);
          return;
        }

        if (membershipTemplate.versions.length === 0) {
          await membershipTemplate.delete();
          navigate(-1);
          return;
        }
        setSelectedVersion(membershipTemplate.versions[0]);
        setSafeDelete(false);
      },
      { success: 'Version gelöscht', error: 'Version konnte nicht gelöscht werden' },
    );
  }

  if (!(membershipTemplate.draft || membershipTemplate.active)) return <Badge>Archiviert</Badge>;
  // return (
  //   <>
  //     <Button $secondary loading={pending} onClick={() => setSafeDelete(true)}>
  //       <span className="text-red-500">Löschen</span>
  //     </Button>
  //     {membershipTemplate.type === 'contract' || membershipTemplate.type === 'addon' ? (
  //       <DeleteModal open={safeDelete} onClose={() => setSafeDelete(false)} />
  //     ) : (
  //       <ConfirmDelete
  //         open={safeDelete}
  //         onClose={() => setSafeDelete(false)}
  //         title="Wirklich löschen?"
  //         isPending={pending}
  //         description="Gelöschte Abos können nicht wieder hergestellt werden"
  //         onDelete={async () => {
  //           await membershipTemplate.delete();
  //           navigate(-1);
  //           setSafeDelete(false);
  //         }}
  //       />
  //     )}
  //   </>
  // );

  switch (selectedVersion?.draftStatus) {
    case 'Entwurf':
    case 'In Vorbereitung':
      return (
        <>
          <Button $secondary loading={pending} onClick={() => setSafeDelete(true)}>
            <span className="text-red-500">Löschen</span>
          </Button>
          {/* {membershipTemplate.type === 'contract' || membershipTemplate.type === 'addon' ? ( */}
          {/*   <DeleteModal open={safeDelete} onClose={() => setSafeDelete(false)} /> */}
          {/* ) : ( */}

          <ConfirmDelete
            open={safeDelete}
            onClose={() => setSafeDelete(false)}
            title={'Wirklich löschen?'}
            isPending={pending}
            description="Gelöschte Versionen können nicht wieder hergestellt werden. Wenn du die letzte Version löscht, wird der ganze Eintrag gelöscht"
            onDelete={deleteVersion}
          />
        </>
      );

    case 'Im Test':
    case 'Wartet auf Freigabe':
      return null;
    default:
      return (
        <>
          <Button $secondary onClick={() => setSafeDelete(true)}>
            <span className="text-red-500">Archivieren</span>
          </Button>
          {membershipTemplate.type === 'contract' || membershipTemplate.type === 'addon' ? (
            <DeleteModal open={safeDelete} onClose={() => setSafeDelete(false)} />
          ) : (
            <ConfirmDelete
              open={safeDelete}
              onClose={() => setSafeDelete(false)}
              title="Wirklich archivieren?"
              isPending={pending}
              description="Soll die aktuelle Version wirklich archiviert werden?"
              onDelete={archive}
              deleteLabel="Archivieren"
            />
          )}
        </>
      );
  }
};
