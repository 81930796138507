import { CheckIcon } from '@heroicons/react/24/solid';
import { Indicator, Root } from '@radix-ui/react-checkbox';
import React from 'react';

export default function Checkbox({
  checked,
  onClick,
  disabled,
}: {
  checked: boolean | 'indeterminate';
  onClick?: (state: any) => void;
  disabled: boolean;
}) {
  return (
    <Root
      disabled={disabled}
      checked={checked}
      onClick={(e) => e.stopPropagation()}
      onCheckedChange={onClick}
      className=" flex h-[25px] border border-gray-200 w-[25px] appearance-none items-center justify-center rounded-[4px] bg-white  outline-none focus:shadow-[0_0_0_2px_black]"
    >
      <Indicator>
        {checked === 'indeterminate' && <div className="w-4 h-0.5 bg-indigo-600" />}
        {checked === true && <CheckIcon className="w-5 h-5 text-indigo-600" />}
      </Indicator>
    </Root>
  );
}
